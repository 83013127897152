import React from 'react'
import Gallery from '@components/Gallery';
import {PortofolioLayout} from "@components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import mockImg from "@images/projects/studysmart/frame-mock.png";
import architectureImg from "@images/projects/studysmart/architecture.png";
const screenshots = importAll(require.context("@images/projects/studysmart/ss", false, /\.(png|jpg|svg)$/));

function importAll(r) {
    return r.keys().map(r);
}


const StudySmartPage = ({ children, location }) => {
    
    return (
        
        <PortofolioLayout title={"Study Smart"}>
            <div className="portofolio-project-wrapper ">
                <div className="portofolio-project studysmart ">
                  {/*   <div className="project-subtitle">
                        <div className="project-status"><span>Status: </span> Completed</div>
                        <div className="project-link"><a href="https://app-study-smart.herokuapp.com/#/" target="_blank">View Application</a></div>
                    </div> */}
                    <img src={mockImg} alt="Studysmart Screenshots" />
                    <div className="project-section project-intro">
                        <h2>Introduction</h2>
                        <p>
                            The project’s aim was to develop a platform that brings closer tutors and students, providing an easier way to find suitable tutors/ students, as well as to manage the process of private lessons.  In order to achieve this, three applications were developed: a web application, a mobile application and RESTful Web service, which is consumed by both of the other apps.
                            <br />
                            This project was submitted for the award of Bachelor's degree in Computer Science from University of Southampton.
                            <br />
                            The software was developed in 7 months, during my 3rd university year (October 2015 - April 2016).
                            <br />
                            The full report is available <a href="https://drive.google.com/file/d/1t3_oIqXR-f6bFf-2TB844Ug3t_Q2b-zw/view?usp=sharing" target="_blank"> here</a>.
                        </p>
                        <div className="project-tags">
                            <span>#AngularJS</span>
                            <span>#NodeJS</span>
                            <span>#Ionic</span>
                            <span>#MongoDB</span>
                            <span>#MongoLab</span>
                            <span>#AWS</span>
                            <span>#Heroku</span>
                            <span>#AgileDevelopment</span>
                        </div>
                    </div>
                    <div className="project-section project-architecture">
                        <h2>Architecture </h2>
                        <img src={architectureImg} alt="Studysmart Architecture" />
                    </div>
                    <div className="project-section project-features">
                        <h2>Features</h2>
                        <ul>
                            <li><FontAwesomeIcon icon="check"></FontAwesomeIcon>User Authentication using PassportJS</li>
                            <li><FontAwesomeIcon icon="check"></FontAwesomeIcon>JWT based Authorization</li>
                            <li><FontAwesomeIcon icon="check"></FontAwesomeIcon>Push Notifications using Ionic Push</li>
                            <li><FontAwesomeIcon icon="check"></FontAwesomeIcon>Using MonogLab - MongoDB DBaaS</li>
                            <li><FontAwesomeIcon icon="check"></FontAwesomeIcon>File uploading (AWS S3)</li>
                            <li><FontAwesomeIcon icon="check"></FontAwesomeIcon>Web app and the API deployed to Heroku</li>
                        </ul>
                    </div>
                    <div className="project-section project-screenshots">
                        <h2>Screenshots</h2>
                        <Gallery images={screenshots} />
                    </div>
                </div>
            </div>
        </PortofolioLayout>
    )
};

export default StudySmartPage;